//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data() {
    return {
      uploader: [{ url: "https://img01.yzcdn.cn/vant/leaf.jpg" }],
      username: "",
      idCord: "",
      sex: "",
      birthday: "",
      // 班级选择
      show: false,
      fieldValue: "",
      cascaderValue: "",
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [
        {
          text: "一年级",
          value: "330000",
          children: [
            { text: "一班", value: "330100" },
            { text: "二班", value: "330100" },
          ],
        },
        {
          text: "二年级",
          value: "330000",
          children: [
            { text: "一班", value: "330100" },
            { text: "二班", value: "330100" },
          ],
        },
      ],
      teacher: "",
    };
  },
  methods: {
    // 提交
    onSubmit(values) {
      console.log("submit", values);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
  },
};
